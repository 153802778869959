$color-blue-wood: rgb(52, 73, 94);
$color-blue-dark: rgb(34, 49, 63);
$color-alice-blue: rgb(228, 241, 254);
$color-white-smoke: rgb(236, 236, 236);
$color-gray-pumike: rgb(210, 215, 211);
$color-honey: rgb(243, 156, 18);
$color-white-dark: #f4f4f4;
$color-soft-red: rgb(236,100,75);
$color-dark-red: rgb(150, 40, 27);

.contact-us-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;

  .form-input-group {
    width: 100%;
    display: flex;
    justify-content: space-between;

    label {
      flex-grow: 0;
      margin-right: 1rem;
    }

    .react-datepicker-wrapper {
      flex-basis: 70%;
      flex-grow: 0;

      input {
        width: 100%;
      }
    }

    input {
      flex-basis: 70%;
      width: 100%;
      flex-shrink: 1;
      margin-bottom: 1rem;
    }
  }

  .form-title {
    display: flex;
    justify-content: center;

    h2 {
      font-weight: 300;
    }
  }

  .message-area {
    height: 7rem;
  }

  textarea {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: inline-block;
    &:focus {
      border: 1px solid #555;
    }
    &::placeholder {
      color: gray;
    }
    resize: none;
  }

  .submit-button {
    text-align: center;
    display: inline-block;
    text-decoration: none;
    width: 100%;
    background: $color-soft-red;
    color: $color-white-smoke;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.1s;

    &:active {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
      transform: translateY(2px);
    }

    &.disabled {
        background: $color-gray-pumike;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
        cursor: not-allowed;
    }
  }
}
