html {
  font-family: sans-serif;
  text-rendering: optimizeLegibility;
  font-weight: 400;
}

input {
  border: 1px solid rgb(118, 118, 118);
  padding: 2px;
  border-radius: 2px;
}

* {
  box-sizing: border-box;
}

.col-2-of-3 {
  width: 66.66%;
}

.col-1-of-3 {
  width: 33.33%;
}

.col-1-of-2 {
  width: 50%;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
