$color-blue-wood: rgb(52, 73, 94);
$color-blue-dark: rgb(34, 49, 63);
$color-alice-blue: rgb(228, 241, 254);
$color-white-smoke: rgb(236, 236, 236);
$color-gray-pumike: rgb(210, 215, 211);
$color-honey: rgb(243, 156, 18);
$color-white-dark: #f4f4f4;
$color-soft-red: rgb(236,100,75);
$color-dark-red: rgb(150, 40, 27);

.ric-header {
  z-index: 10;
  position: fixed;
  height: 3rem;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.4);
  display: flex;
  top: 0;
  transition: 0.2s all linear;

  .header-logo {
    display: flex;
    align-items: center;

    svg {
      height: 2.5rem;
      width: 5rem;
      fill: $color-white-smoke;
    }
  }

  .header-lang-dropdown {
    display: flex;
    align-items: center;

    button {
      border: none;
    }
  }

  .header-menu {
    display: flex;
    margin-left: auto;

    .header-link {
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;
      text-align: center;
      color: $color-white-smoke;
      width: 10rem;
      height: 3rem;
      line-height: 3rem;
      transition: all 0.1s linear;
      font-size: 0.9rem;

      &:hover {
        background: $color-white-smoke;
        color: $color-blue-wood;
      }
    }
  }

  &.state-clear {
    background: white;

    .header-link {
      color: $color-blue-wood;

      &:hover {
        color: $color-white-smoke;
        background: $color-blue-wood;
      }
    }

    svg {
      fill: $color-blue-wood;
    }
  }
}
