$color-blue-wood: rgb(52, 73, 94);
$color-blue-dark: rgb(34, 49, 63);
$color-alice-blue: rgb(228, 241, 254);
$color-white-smoke: rgb(236, 236, 236);
$color-gray-pumike: rgb(210, 215, 211);
$color-honey: rgb(243, 156, 18);
$color-white-dark: #f4f4f4;
$color-soft-red: rgb(236,100,75);
$color-dark-red: rgb(150, 40, 27);

.ric-car-card {
  flex: 0 0 25%;
  min-width: 19rem;
  border: 1px solid $color-gray-pumike;
  background: white;
  box-shadow: 0 0 0.125rem $color-gray-pumike;

  .car-card-image {
    border-bottom: 2px solid $color-gray-pumike;
    cursor: pointer;
    overflow: hidden;
    height: 11rem;


    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 0.9;
      transform: scale(1.02);
      transition: all 0.3s;

      &:hover {
        transform: scale(1.07);
        opacity: 1;
      }
    }
  }

  .car-card-title {
    text-rendering: optimizeLegibility;
    text-align: center;
    font-weight: 300;
    font-size: 1.25rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }

  .car-card-details {
    padding: 1rem;
  }

  .card-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .card-btn {
      flex: 1;
      //width: 50%;

      span {
        border-radius: 0;
        border: 0;
      }
    }
  }
}

.ric-car-detail {
  display: flex;
  align-items: center;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  margin-bottom: 0.25rem;

  svg {
    height: 2rem;
    width: 2rem;
    margin-right: 0.5rem;
    fill: $color-blue-wood;
  }

  .detail-value {
    text-transform: capitalize;
    font-weight: 400;
    margin-left: auto;
  }
}
