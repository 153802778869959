$color-blue-wood: rgb(52, 73, 94);
$color-blue-dark: rgb(34, 49, 63);
$color-alice-blue: rgb(228, 241, 254);
$color-white-smoke: rgb(236, 236, 236);
$color-gray-pumike: rgb(210, 215, 211);
$color-honey: rgb(243, 156, 18);
$color-white-dark: #f4f4f4;
$color-soft-red: rgb(236,100,75);
$color-dark-red: rgb(150, 40, 27);

.ric-button {
  $main-color: $color-soft-red;
  $second-color: $color-white-dark;
  $main-color-blue: $color-blue-wood;

  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  font-size: 1.25rem;
  cursor: pointer;
  font-weight: 400;
  text-rendering: optimizeLegibility;

  span {
    background: $main-color;
    display: block;
    padding: 0.5rem 1.5rem;
    border: 1px solid $main-color;
    border-radius: 0.25rem;
    color: $second-color;
    transition: all 0.2s;

    &:hover {
      background: $second-color;
      color: $main-color;
    }

    &.theme-blue {
      background: $main-color-blue;
      border-color: $main-color-blue;

      &:hover {
        background: $second-color;
        color: $main-color-blue;
      }
    }
  }
}
