$color-blue-wood: rgb(52, 73, 94);
$color-blue-dark: rgb(34, 49, 63);
$color-alice-blue: rgb(228, 241, 254);
$color-white-smoke: rgb(236, 236, 236);
$color-gray-pumike: rgb(210, 215, 211);
$color-honey: rgb(243, 156, 18);
$color-white-dark: #f4f4f4;
$color-soft-red: rgb(236,100,75);
$color-dark-red: rgb(150, 40, 27);

.car-page {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .car-price-table {
    margin-top: 1rem;
    flex-grow: 1;
    flex-basis: 500px;
  }

  .car-contact-form {
    margin-top: 2rem;

    width: 100%;
    display: flex;
    justify-content: start;

    .form {
      width: 20rem;
    }
  }

  .card-container {
    flex-grow: 1;
    height: 100%;
    max-width: 600px;
    margin-right: 2rem;

    .car-card-image {
      height: 22rem;
      cursor: default;
    }
  }

  .car-carousel {
    width: 100%;
  }

  .car-section-title {
    font-size: 1.5rem;
  }
}

.car-page-title {
  color: $color-blue-wood;
  text-align: center;
  font-weight: 700;
  font-size: 3rem;
  letter-spacing: 1px;
  word-spacing: 4px;
  margin-top: 6rem;
}
