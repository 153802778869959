$color-blue-wood: rgb(52, 73, 94);
$color-blue-dark: rgb(34, 49, 63);
$color-alice-blue: rgb(228, 241, 254);
$color-white-smoke: rgb(236, 236, 236);
$color-gray-pumike: rgb(210, 215, 211);
$color-honey: rgb(243, 156, 18);
$color-white-dark: #f4f4f4;
$color-soft-red: rgb(236,100,75);
$color-dark-red: rgb(150, 40, 27);

.ric-cars-container {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, auto));
  width: 100%;

  .card-wrapper {
    flex: 0 0 25%;
    padding: 1rem;
  }
}
