$color-blue-wood: rgb(52, 73, 94);
$color-blue-dark: rgb(34, 49, 63);
$color-alice-blue: rgb(228, 241, 254);
$color-white-smoke: rgb(236, 236, 236);
$color-gray-pumike: rgb(210, 215, 211);
$color-honey: rgb(243, 156, 18);
$color-white-dark: #f4f4f4;
$color-soft-red: rgb(236,100,75);
$color-dark-red: rgb(150, 40, 27);

.ric-section {
  margin: 0 auto;
  padding: 2rem 0;


  .section-title {
    margin: 0 auto;
    text-align: center;
    font-size: 3rem;
    font-weight: 300;
    color: $color-blue-wood;

    &::after {
      display: block;
      background-color: $color-soft-red;
      width: 10rem;
      content: " ";
      margin: 0.5rem auto;
      height: 0.125rem;
    }
  }

  .section-items {
    margin: 4rem auto 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 80rem;
  }
}
