$color-blue-wood: rgb(52, 73, 94);
$color-blue-dark: rgb(34, 49, 63);
$color-alice-blue: rgb(228, 241, 254);
$color-white-smoke: rgb(236, 236, 236);
$color-gray-pumike: rgb(210, 215, 211);
$color-honey: rgb(243, 156, 18);
$color-white-dark: #f4f4f4;
$color-soft-red: rgb(236,100,75);
$color-dark-red: rgb(150, 40, 27);

.ric-footer {
  background: $color-blue-dark;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .footer-body {
    width: 100%;
    margin: 3rem 0;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .footer-links {
    width: 50%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .footer-link {
    color: $color-gray-pumike;
    margin: 1rem;
    cursor: pointer;
    transition: color 0.3s, text-shadow 0.3s ease-in-out;
    font-size: 1rem;
    font-weight: 300;

    &:hover {
      color: white;
      text-shadow: 0 1px 1px rgba(255, 255, 255, 0.15);
    }
  }

  .footer-social {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .social-links {
    display: flex;
    flex-wrap: wrap;
  }

  .social-link {
    display: flex;
    align-items: center;
    text-decoration: none;

    .fab {
      font-size: 2rem;
      margin-right: 0.25rem;
    }

    margin: 0.5rem;
  }

  .footer-copyright {
    color: white;
    font-weight: 300;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
}
