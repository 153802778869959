$color-blue-wood: rgb(52, 73, 94);
$color-blue-dark: rgb(34, 49, 63);
$color-alice-blue: rgb(228, 241, 254);
$color-white-smoke: rgb(236, 236, 236);
$color-gray-pumike: rgb(210, 215, 211);
$color-honey: rgb(243, 156, 18);
$color-white-dark: #f4f4f4;
$color-soft-red: rgb(236,100,75);
$color-dark-red: rgb(150, 40, 27);

.ric-trait {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  max-width: 17.5rem;
  margin: 0 1.25rem 3rem;

  .item-title {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 300;
  }

  .item-description {
    font-weight: 300;
  }

  svg {
    fill: $color-soft-red;
    height: 6rem;
    width: auto;
    transition: all 0.4s;

    &:hover {
      transform: scale(1.1);
    }
  }
}
